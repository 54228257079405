import axios from "axios";
import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import jwt_decode from "jwt-decode";
import { Link } from "react-router-dom";

const toastOptions = {
  position: "top-center",
  autoClose: 2000,
  hideProgressBar: false,
  newestOnTop: false,
  closeOnClick: true,
  pauseOnFocusLoss: true,
  draggable: true,
  pauseOnHover: true,
  theme: "dark",
};

const Leads = () => {
  const [leadData, setLeadData] = useState([]);
  const [selectedRow, setSelectedRow] = useState(null);
  const [editingLeadId, setEditingLeadId] = useState(null);
  const [editingLeadData, setEditingLeadData] = useState(null); // State to keep track of temporary lead data during editing
  const [userId, setUserId] = useState("");

  //pagnation
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(11);
  //serach

  const [filteredLeads, setFilteredLeads] = useState([]);
  const [searchFromDate, setSearchFromDate] = useState("");
  const [searchCountry, setSearchCountry] = useState("");
  // const [filteredData,setFilteredData]  = useState("")

  useEffect(() => {
    (async () => {
      const userToken = await localStorage.getItem("crm_admin_token");
      const user = jwt_decode(userToken);
      setUserId(user.id);
    })();
  }, []);

  //pagination
  const fetchLeadData = async () => {
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    const response = await axios.get(
      `https://crm-alpha-blond.vercel.app/api/v1/getAll-leads/${userId}`
    );
    const data = response.data.slice(startIndex, endIndex);
    setLeadData(data);
  };

  //GET ALL LEADS
  useEffect(() => {
    (async () => {
      let res = await axios.get(
        // `https://crm-alpha-blond.vercel.app/api/v1/getAll-leads/${userId}`
        `https://crm-alpha-blond.vercel.app/api/v1/getAll-leads/${userId}`
      );
      setLeadData(res.data);
      setFilteredLeads(res.data);
    })();
  }, [userId, currentPage, itemsPerPage]);
  //handle search
  const handleDateSearch = () => {
    function filterCountry(data) {
      return data.countryName.toLowerCase() === searchCountry.toLowerCase();
    }

    let temp = leadData.map((item) => ({
      ...item,
      countryName: item.countryName.toLowerCase(),
    }));

    temp = temp.filter(filterCountry);

    setFilteredLeads(temp);
  };

  // Implement functions to handle page changes
  const handleNextPage = () => {
    setCurrentPage(currentPage + 1);
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  //update lead API
  const handleUpdateLead = async (leadId) => {
    try {
      await axios.patch(
        // `https://crm-alpha-blond.vercel.app/api/v1/${userId}/update-leads/${leadId}`,
        `https://crm-alpha-blond.vercel.app/api/v1/${userId}/update-leads/${leadId}`,
        editingLeadData
      );
      setLeadData((prevLeadData) =>
        prevLeadData.map((lead) =>
          lead._id === leadId ? { ...editingLeadData } : lead
        )
      );
      setEditingLeadData(null);
      setEditingLeadId(null);
      toast.success("Lead updeted successfully!", toastOptions);
    } catch (error) {
      toast.error("An error occurred while updating the lead.", toastOptions);
    }
  };

  //delete lead API
  const handleDeleteLead = async (leadId) => {
    try {
      await axios.delete(
        // `https://crm-alpha-blond.vercel.app/api/v1/${userId}/delete-lead/${leadId}`
        `https://crm-alpha-blond.vercel.app/api/v1/${userId}/delete-lead/${leadId}`
      );

      setLeadData((prevLeadData) =>
        prevLeadData.filter((lead) => lead._id !== leadId)
      );
      toast.success("Lead deleted successfully!", toastOptions);
    } catch (error) {
      toast.error("An error occurred while deleting the lead.", toastOptions);
    }
  };

  //date and time
  const convertToIndianTime = (utcDateTime) => {
    const utcTime = new Date(utcDateTime);
    const options = {
      timeZone: "Asia/Kolkata",
      year: "numeric",
      month: "numeric",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      second: "numeric",
    };
    const indianTime = new Date(utcTime.toLocaleString("en-US", options));
    return indianTime.toLocaleString("en-US", options);
  };

  const handleRowClick = (rowId) => {
    setSelectedRow(rowId === selectedRow ? null : rowId);
  };

  const toggleEditField = (leadId) => {
    if (editingLeadId === leadId) {
      setLeadData((prevLeadData) =>
        prevLeadData.map((lead) =>
          lead._id === leadId ? { ...editingLeadData } : lead
        )
      );

      setEditingLeadData(null);
      setEditingLeadId(null);
    } else {
      // Start editing and create a temporary data object
      const leadToEdit = leadData.find((lead) => lead._id === leadId);
      setEditingLeadData({ ...leadToEdit });
      setEditingLeadId(leadId);
    }
  };

  const cancelEdit = () => {
    setEditingLeadData(null);
    setEditingLeadId(null);
  };
  return (
    <div className="overflow-x-auto min-h-screen bg-gray-900 text-white">
      <div className="flex justify-center my-4  ">
        {/* <input
          type="date"
          placeholder="Search by from date"
          value={searchFromDate}
          onChange={(e) => setSearchFromDate(e.target.value)}
          className="px-4 py-2  text-black"
        />
         <button
          onClick={handleDateSearch}
          className="mr-8 px-4 py-2 bg-gray-600 text-white"
        >
          Search
        </button> */}

        <input
          type="text"
          placeholder="Search by country"
          value={searchCountry}
          onChange={(e) => setSearchCountry(e.target.value)}
          className="px-4 py-2   text-black"
        />
        <button
          onClick={handleDateSearch}
          className="mr-8 px-4 py-2 bg-gray-600 text-white"
        >
          Search
        </button>
      </div>
      <table className="min-w-full divide-y divide-gray-200">
        <thead>
          <tr className="item-center justify-center font-extrabold text-gray-500 px-6 py-3">
            <th className="px-4 py-3 text-left text-xs font-medium text-gray-300 uppercase tracking-wider">
              Index
            </th>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-300 uppercase tracking-wider">
              Name
            </th>
            <th className="px-9 py-3 text-left text-xs font-medium text-gray-300 uppercase tracking-wider">
              Phone
            </th>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-300 uppercase tracking-wider">
              Country
            </th>
            <th className="px-5 py-3 text-left text-xs font-medium text-gray-300 uppercase tracking-wider">
              Investment
            </th>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-300 uppercase tracking-wider">
              Segments
            </th>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-300 uppercase tracking-wider">
              Language
            </th>
            <th className="px-9 py-3 text-left text-xs font-medium text-gray-300 uppercase tracking-wider">
              Referance
            </th>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-300 uppercase tracking-wider">
              Assigned_to
            </th>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-300 uppercase tracking-wider">
              Assigned_by
            </th>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-300 uppercase tracking-wider">
              Pool
            </th>
            <th className="px-12 py-3 text-left text-xs font-medium text-gray-300 uppercase tracking-wider">
              Date And Time
            </th>
            <th className="px-12 py-3 text-left text-xs font-medium text-gray-300 uppercase tracking-wider">
              Remark
            </th>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-300 uppercase tracking-wider">
              Title
            </th>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-300 uppercase tracking-wider">
              Edit/Delete
            </th>
          </tr>
        </thead>
        {/* ... (thead and tbody code) */}
        <tbody className="bg-gray-900 text-white divide-y divide-gray-200">
          {filteredLeads &&
            filteredLeads.map((lead, index) => (
              <tr
                key={lead._id}
                className={selectedRow === lead._id ? "bg-blue-300" : ""}
                onClick={() => handleRowClick(lead._id)}
              >
                <td className="px-6 py-4 whitespace-nowrap">{index + 1}</td>
                <td className="px-6 py-4 whitespace-nowrap">
                  {editingLeadId === lead._id ? (
                    <>
                      <p>Name</p>
                      <input
                        className="text-black"
                        type="text"
                        value={editingLeadData.name}
                        onChange={(e) =>
                          setEditingLeadData({
                            ...editingLeadData,
                            name: e.target.value,
                          })
                        }
                      />
                    </>
                  ) : (
                    lead.name
                  )}
                </td>

                <td className="px-6 py-4 whitespace-nowrap">
                  {editingLeadId === lead._id ? (
                    <>
                      <p>Phone</p>
                      <input
                        className="text-black"
                        type="text"
                        value={editingLeadData.phone}
                        onChange={(e) =>
                          setEditingLeadData({
                            ...editingLeadData,
                            phone: e.target.value,
                          })
                        }
                      />
                    </>
                  ) : (
                    lead.phone
                  )}
                </td>

                <td className="px-6 py-4 whitespace-nowrap">
                  {editingLeadId === lead._id ? (
                    <>
                      <p>Country</p>
                      <input
                        className="text-black"
                        type="text"
                        value={editingLeadData.countryName}
                        onChange={(e) =>
                          setEditingLeadData({
                            ...editingLeadData,
                            countryName: e.target.value,
                          })
                        }
                      />
                    </>
                  ) : (
                    lead.countryName
                  )}
                </td>

                <td className="px-6 py-4 whitespace-nowrap">
                  {editingLeadId === lead._id ? (
                    <>
                      <p>Investment</p>
                      <input
                        className="text-black"
                        type="text"
                        value={editingLeadData.investment}
                        onChange={(e) =>
                          setEditingLeadData({
                            ...editingLeadData,
                            investment: e.target.value,
                          })
                        }
                      />
                    </>
                  ) : (
                    lead.investment
                  )}
                </td>

                <td className="px-6 py-4 whitespace-nowrap">
                  {editingLeadId === lead._id ? (
                    <>
                      <p>Segments</p>
                      <input
                        className="text-black"
                        type="text"
                        value={editingLeadData.segments}
                        onChange={(e) =>
                          setEditingLeadData({
                            ...editingLeadData,
                            segments: e.target.value,
                          })
                        }
                      />
                    </>
                  ) : (
                    lead.tradeIn
                  )}
                </td>
                <td className="px-6 py-4 whitespace-nowrap">
                  {editingLeadId === lead._id ? (
                    <>
                      <p>Language</p>
                      <input
                        className="text-black"
                        type="text"
                        value={editingLeadData.language}
                        onChange={(e) =>
                          setEditingLeadData({
                            ...editingLeadData,
                            language: e.target.value,
                          })
                        }
                      />
                    </>
                  ) : (
                    lead.language
                  )}
                </td>

                <td className="px-6 py-4 whitespace-nowrap">
                  {editingLeadId === lead._id ? (
                    <>
                      <p>Referance</p>
                      <input
                        className="text-black"
                        type="text"
                        value={editingLeadData.reference}
                        onChange={(e) =>
                          setEditingLeadData({
                            ...editingLeadData,
                            reference: e.target.value,
                          })
                        }
                      />
                    </>
                  ) : (
                    lead.reference
                  )}
                </td>

                <td className="px-6 py-4 whitespace-nowrap">
                  {editingLeadId === lead._id ? (
                    <>
                      <p>Assigned_to</p>
                      <input
                        className="text-black"
                        type="text"
                        value={editingLeadData.assigned_to.firstName}
                        onChange={(e) =>
                          setEditingLeadData({
                            ...editingLeadData,
                            assigned_to: e.target.value,
                          })
                        }
                      />
                    </>
                  ) : lead.assigned_to ? (
                    lead.assigned_to.firstName
                  ) : (
                    "unassigned"
                  )}
                </td>

                <td className="px-6 py-4 whitespace-nowrap">
                  {editingLeadId === lead._id ? (
                    <>
                      <p>Assigned_by</p>
                      <input
                        className="text-black"
                        type="text"
                        value={editingLeadData.assigned_by.firstName}
                        onChange={(e) =>
                          setEditingLeadData({
                            ...editingLeadData,
                            assigned_by: e.target.value,
                          })
                        }
                      />
                    </>
                  ) : lead.assigned_by ? (
                    lead.assigned_by.firstName
                  ) : (
                    "unassigned"
                  )}
                </td>

                <td className="px-6 py-4 whitespace-nowrap">
                  {editingLeadId === lead._id ? (
                    <>
                      <p>Pool</p>
                      <input
                        className="text-black"
                        type="text"
                        value={editingLeadData.pool}
                        onChange={(e) =>
                          setEditingLeadData({
                            ...editingLeadData,
                            pool: e.target.value,
                          })
                        }
                      />
                    </>
                  ) : (
                    lead.pool
                  )}
                </td>

                <td className="px-6 py-4 whitespace-nowrap">
                  {editingLeadId === lead._id ? (
                    <>
                      <input
                        className="text-black"
                        type="text"
                        value={convertToIndianTime(lead.createdAt)}
                        onChange={(e) => {}}
                        disabled
                      />
                    </>
                  ) : (
                    convertToIndianTime(lead.createdAt)
                  )}
                </td>

                <td className="px-6 py-4 whitespace-nowrap">
                  {editingLeadId === lead._id ? (
                    <>
                      <p>Remark</p>
                      <input
                        className="text-black"
                        type="text"
                        value={editingLeadData.remark}
                        onChange={(e) =>
                          setEditingLeadData({
                            ...editingLeadData,
                            remark: e.target.value,
                          })
                        }
                      />
                    </>
                  ) : (
                    lead.remark
                  )}
                </td>
                <td className="px-6 py-4 whitespace-nowrap">
                  {editingLeadId === lead._id ? (
                    <>
                      <p>Title</p>
                      <input
                        className="text-black"
                        type="text"
                        value={editingLeadData.remark}
                        onChange={(e) =>
                          setEditingLeadData({
                            ...editingLeadData,
                            title: e.target.value,
                          })
                        }
                      />
                    </>
                  ) : (
                    lead.title
                  )}
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-white text-xl space-x-4">
                  {editingLeadId === lead._id ? (
                    <>
                      <i
                        className="fa-solid  fa-check hover:text-green-500 cursor-pointer"
                        onClick={() => handleUpdateLead(lead._id)}
                      ></i>
                      <i
                        className="fa-solid fa-xmark hover:text-red-500 cursor-pointer"
                        onClick={cancelEdit}
                      ></i>
                    </>
                  ) : (
                    <>
                      <i
                        className="fa-solid fa-user-pen hover:text-blue-500 cursor-pointer"
                        onClick={() => toggleEditField(lead._id)}
                      ></i>
                      <i
                        className="fa-solid fa-trash hover:text-red-500 cursor-pointer"
                        onClick={() => handleDeleteLead(lead._id)}
                      ></i>
                    </>
                  )}
                </td>
              </tr>
            ))}
        </tbody>
      </table>
      <div>
        <div className="flex justify-center my-4">
          <button
            onClick={handlePreviousPage}
            disabled={currentPage === 1}
            className="px-4 py-2 bg-gray-600 text-white mr-2"
          >
            Previous
          </button>
          <button
            onClick={handleNextPage}
            disabled={leadData.length < itemsPerPage}
            className="px-4 py-2 bg-gray-600 text-white ml-2"
          >
            Next
          </button>
        </div>
        <Link to="/addLeads">
          <button
            className=" flex-end  bg-gradient-to-r from-teal-400 to-blue-500 hover:from-pink-500 hover:to-orange-500 text-white font-semibold px-4 py-2 rounded"
            type="submit"
          >
            ADD LEADS
          </button>
        </Link>
      </div>
    </div>
  );
};
export default Leads;
