import axios from "axios";
import React, { useEffect, useState } from "react";
import jwt_decode from "jwt-decode";
import { setSelectedTab } from "../redux/reducers/selectedTab";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";

const toastOptions = {
  position: "top-center",
  autoClose: 2000,
  hideProgressBar: false,
  newestOnTop: false,
  closeOnClick: true,
  pauseOnFocusLoss: true,
  draggable: true,
  pauseOnHover: true,
  theme: "dark",
};

const Home = () => {
  const [userData, setUserData] = useState([]);
  const [selectedRow, setSelectedRow] = useState(null);
  const selectedTab = useSelector((state) => state.selectedTab);
  const [filtereData, setFilterdData] = useState(userData);
  const [currentUser, setCurrentUser] = useState({
    id: "",
    firstName: "",
  });
  const navigate = useNavigate();



  const fetchData = async () => {
    try {
      const token = localStorage.getItem("crm_admin_token");
      const user = jwt_decode(token);
      setCurrentUser({
        id: user.id,
        firstName: user.firstName,
      });

      const res = await axios.get(
        `https://crm-alpha-blond.vercel.app/api/v1/assignedByMe/${user.id}`,
        {
          id: user.id,
        }
      );
      setUserData(res.data);
    } catch (error) {
      toast.error("An error occurred while fetching users.", toastOptions);
    }
  };


  useEffect(() => {
    fetchData();
    const refreshInterval = 5 * 60 * 1000; // 5 minutes in milliseconds
    const refreshTimer = setInterval(fetchData, refreshInterval);
    return () => {
      clearInterval(refreshTimer); // Clean up the interval when the component unmounts
    };
  }, []);

  useEffect(() => {
    console.log(filtereData);
  }, [filtereData]);

  useEffect(() => {
    (async () => {
      try {
        const token = localStorage.getItem("crm_admin_token");
        const user = jwt_decode(token);
        setCurrentUser({
          id: user.id,
          firstName: user.firstName,
        });
      } catch (error) {
        toast.error("An error occurred while fetching users.", toastOptions);
      }
    })();
  }, []);

  useEffect(() => {
    console.log(filtereData);
  }, [filtereData]);

  const handleFilteredData = (tab) => {
    const filteredTabData = userData.filter((data) => {
      if (data.pool.toUpperCase() === tab) {
        return data;
      } else if (selectedTab === "all") {
        return userData;
      }
    });
    setFilterdData(filteredTabData);
  };

  useEffect(() => {
    console.log(selectedTab);
  }, []);

  useEffect(() => {
    handleFilteredData(selectedTab);
  }, [selectedTab]);

  useEffect(() => {
    setFilterdData(userData);
  }, [userData]);

  useEffect(() => {
    (async () => {
      const token = await localStorage.getItem("crm_admin_token");
      const role = jwt_decode(token).role;
      const id = jwt_decode(token).id;
      // if (role === "Admin") {
      //   navigate("/leads");
      // } else 
      if(role === "user" || role === "Manager" || role === "Admin"){
        const res = await axios.get(
          // `https://crm-alpha-blond.vercel.app/api/v1/assigned-leads/${id}`,
          `https://crm-alpha-blond.vercel.app/api/v1/assignedByMe/${id}`,
          {
            id,
          }
        );
        console.log(res.data);
        setUserData(res.data);
      }
    })();
  }, []);

  const convertToIndianTime = (utcDateTime) => {
    const utcTime = new Date(utcDateTime);
    const indianTime = new Date(
      utcTime.toLocaleString("en-US", { timeZone: "Asia/Kolkata" })
    );
    return indianTime.toLocaleString();
  };

  const handleRowClick = (rowId) => {
    setSelectedRow(rowId === selectedRow ? null : rowId);
  };

  return (
    <div className="overflow-x-auto text-white bg-gray-900 xl:min-h-screen">
      <p className="text-center my-4">
        logged in as :{" "}
        <span className="font-bold capitalize">{currentUser.firstName}</span>
      </p>
      {userData.length === 0 ? (
        <p className="text-center my-4">// You haven't assigned any Leads</p>
      ) : (
        <>
          <p className="text-center my-4">Leads assigned by you</p>
          <Tabs data={userData} />
          <table className="min-w-full divide-y divide-gray-200 ">
            <thead className="bg-gray-50">
              <tr>
              <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                INDEX
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Name
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Phone
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Country
                </th>
                <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Investment
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Segments
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Language
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Reference
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"> 
                  Title
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Assigned_to
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Pool
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                 Date And Time
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Remark
                </th>

              </tr>
            </thead>
            <tbody className="bg-gray-900 divide-y divide-gray-200">
              {filtereData.map((user,index) => (
                <tr
                  key={user._id}
                  className={
                    selectedRow === user._id ? "bg-blue-200 text-black" : ""
                  }
                  onClick={() => handleRowClick(user._id)}
                >
                 <td className="px-6 py-4 whitespace-nowrap">{index + 1}</td>
                  <td className="px-6 py-4 whitespace-nowrap">{user.name}</td>
                  {/* <td className="px-6 py-4 whitespace-nowrap">{user.email}</td> */}
                  <td className="px-6 py-4 whitespace-nowrap">{user.phone}</td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    {user.countryName}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    {user.investment}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    {user.segments}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    {user.language}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    {user.reference}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    {user.title}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    {user.assigned_to && user.assigned_to.firstName}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">{user.pool}</td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    {convertToIndianTime(user.createdAt)}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">{user.remark}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </>
      )}
    </div>
  );
};
export default Home;

const Tabs = (data) => {
 
  const [tabs, setTabs] = useState([]);
  const dispatch = useDispatch();
  useEffect(() => {
    const uniqueTabs = new Set(tabs); // Create a Set from the current tabs
    data.data.forEach((tab) => {
      const modifiedTabName = tab.pool.toUpperCase();
      uniqueTabs.add(modifiedTabName); // Add each modifiedTabName to the Set
    });
    setTabs(Array.from(uniqueTabs)); // Convert the Set back to an array and update the state
  }, [data]);
  const [isSelected, setIsSelected] = useState(-1);

  return (
    <div className="w-full p-1 border border-black overflow-x-auto my-4">
      <button
        className={`px-4 m-2 py-2 border border-black mx-2 ${
          isSelected === -1 ? "bg-black border-blue-600 text-white" : ""
        }`}
        onClick={() => {
          setIsSelected(-1);
          dispatch(setSelectedTab("all"));
        }}
      > 

        All
      </button>
      {tabs.map((tab, index) => {
        return (
          <>
            <button
              className={`px-4 m-2 py-2 border border-black mx-2 ${
                isSelected === index
                  ? "bg-black border-blue-600 text-white"
                  : ""
              }`}
              key={index}
              onClick={() => {
                setIsSelected(index);
                dispatch(setSelectedTab(tab));
              }}
            >
              {tab}                                   
            </button>
          </>
        );
      })}
    </div>
  );
};




