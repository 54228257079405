import axios from "axios";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import jwtDecode from "jwt-decode";

const toastOptions = {
  position: "top-center",
  autoClose: 2000,
  hideProgressBar: false,
  newestOnTop: false,
  closeOnClick: true,
  pauseOnFocusLoss: true,
  draggable: true,
  pauseOnHover: true,
  theme: "dark",
};

const Users = () => {
  const [userData, setUserData] = useState([]);
  const [selectedRow, setSelectedRow] = useState(null);
  const [editingUserId, setEditingUserId] = useState(null);
  const [editingUserData, setEditingUserData] = useState(null); // State to keep track of temporary user data during editing
  const [id, setId] = useState("");

  useEffect(() => {
    (async () => {
      const userToken = await localStorage.getItem("crm_admin_token");
      const user = jwtDecode(userToken);
      setId(user.id);
      let res = await axios.get(
        // `https://crm-alpha-blond.vercel.app/api/v1/all-users/${user.id}`
        `https://crm-alpha-blond.vercel.app/api/v1/all-users/${user.id}`
      );
      setUserData(res.data);
    })();
  }, [id]);

  const convertToIndianTime = (utcDateTime) => {
    const utcTime = new Date(utcDateTime);
    const indianTime = new Date(
      utcTime.toLocaleString("en-US", { timeZone: "Asia/Kolkata" })
    );
    return indianTime.toLocaleString();
  };

  const handleUpdateUser = async (userId) => {
    try {
      await axios.patch(
        // `https://crm-alpha-blond.vercel.app/api/v1/${id}/update-users/${userId}`,
        `https://crm-alpha-blond.vercel.app/api/v1/${id}/update-users/${userId}`,
        editingUserData
      );
      setUserData((prevUserData) =>
        prevUserData.map((user) =>
          user._id === userId ? { ...editingUserData } : user
        )
      );
      setEditingUserData(null);
      setEditingUserId(null);
      toast.success("User updeted successfully!", toastOptions);
    } catch (error) {
      toast.error("An error occurred while updating the user.", toastOptions);
    }
  };

  const handleDeleteUser = async (userId) => {
    try {
      await axios.delete(
        // `https://crm-alpha-blond.vercel.app/api/v1/${id}/delete-users/${userId}`
        `https://crm-alpha-blond.vercel.app/api/v1/${id}/delete-users/${userId}`
      );
      setUserData((prevUserData) =>
        prevUserData.filter((user) => user._id !== userId)
      );
      toast.success("User deleted successfully", toastOptions);
    } catch (error) {
      toast.error("An error occurred while deleting the user.", toastOptions);
    }
  };

  const handleRowClick = (rowId) => {
    setSelectedRow(rowId === selectedRow ? null : rowId);
  };

  const toggleEditField = (userId) => {
    if (editingUserId === userId) {
      setUserData((prevUserData) =>
        prevUserData.map((user) =>
          user._id === userId ? { ...editingUserData } : user
        )
      );
      setEditingUserData(null);
      setEditingUserId(null);
    } else {
      const userToEdit = userData.find((user) => user._id === userId);
      setEditingUserData({ ...userToEdit });
      setEditingUserId(userId);
    }
  };
  const cancelEdit = () => {
    setEditingUserData(null);
    setEditingUserId(null);
  };

  return (
    <>
      <div className="h-full xl:min-h-screen w-full bg-gray-900  pt-4">
        {userData.length === 0 ? (
          <p className="text-center">// there are currently no users</p>
        ) : (
          <div className="overflow-x-auto">
            <table className="min-w-full divide-y">
            <thead>
          <tr>
            <th className="px-4 py-3 text-left text-xs font-medium text-gray-300 uppercase tracking-wider">
              Index
            </th>
            <th className="px-7 py-3 text-left text-xs font-medium text-gray-300 uppercase tracking-wider">
              Name
            </th>
            <th className="px-24 py-3 text-left text-xs font-medium text-gray-300 uppercase tracking-wider">
            Email
            </th>
            <th className="px-7 py-3 text-left text-xs font-medium text-gray-300 uppercase tracking-wider">
         Role
            </th>
            <th className="px-12 py-3 text-left text-xs font-medium text-gray-300 uppercase tracking-wider">
       Time And Date
            </th>
            <th className="px-5 py-3 text-left text-xs font-medium text-gray-300 uppercase tracking-wider">
        Edit/Delte
            </th>
          </tr>
        </thead>
              {/* ... (thead and tbody code) */}
              <tbody className=" text-white">
                {userData.map((user,index) => (
                  <tr
                    key={user._id}
                    className={selectedRow === user._id ? "bg-blue-300" : ""}
                    onClick={() => handleRowClick(user._id)}
                  >
                     <td className="px-6 items-center justify-center py-4 whitespace-nowrap">{index + 1}</td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      {editingUserId === user._id ? (
                        <>
                          <input
                            className="text-black"
                            type="text"
                            value={editingUserData.firstName}
                            onChange={(e) =>
                              setEditingUserData({
                                ...editingUserData,
                                firstName: e.target.value,
                              })
                            }
                          />
                        </>
                      ) : (
                        user.firstName
                      )}
                    </td>

                    <td className="px-6 py-4 whitespace-nowrap">
                      {editingUserId === user._id ? (
                        <>
                          <input
                            className="text-black"
                            type="text"
                            value={editingUserData.email}
                            onChange={(e) =>
                              setEditingUserData({
                                ...editingUserData,
                                email: e.target.value,
                              })
                            }
                          />
                        </>
                      ) : (
                        user.email
                      )}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      {editingUserId === user._id ? (
                        <>
                          <select
                            className="text-black"
                            onChange={(e) =>
                              setEditingUserData({
                                ...editingUserData,
                                role: e.target.value,
                              })
                            }
                          >
                            <option defaultValue={"User"}>User</option>
                            <option value={"Admin"}>Admin</option>
                            <option value={"Manager"}>Manager</option>
                          </select>
                        </>
                      ) : (
                        user.role
                      )}
                    </td>

                    <td className="px-6 py-4 whitespace-nowrap">
                      {editingUserId === user._id ? (
                        <>
                          <input
                            className="text-black"
                            type="text"
                            value={convertToIndianTime(user.createdAt)}
                            onChange={(e) => {}}
                            disabled
                          />
                        </>
                      ) : (
                        convertToIndianTime(user.createdAt)
                      )}
                    </td>

                    {/* <td className="px-6 py-4 whitespace-nowrap">
                  {editingUserId === user._id ? (
                    <>
                      <input
                        type="text"
                        value={convertToIndianTime(user.updatedAt)}
                        onChange={(e) => {}}
                        disabled
                      />
                    </>
                  ) : (
                    convertToIndianTime(user.updatedAt)
                  )}
                </td> */}

                    <td className="px-6 py-4 whitespace-nowrap text-xl space-x-4">
                      {editingUserId === user._id ? (
                        <>
                          <i
                            className="fa-solid fa-check hover:text-green-500 cursor-pointer"
                            onClick={() => handleUpdateUser(user._id)}
                          ></i>
                          <i
                            className="fa-solid fa-xmark hover:text-red-500 cursor-pointer"
                            onClick={cancelEdit}
                          ></i>
                        </>
                      ) : (
                        <>
                          <i
                            className="fa-solid fa-user-pen hover:text-blue-500 cursor-pointer"
                            onClick={() => toggleEditField(user._id)}
                          ></i>
                          <i
                            className="fa-solid fa-trash hover:text-red-500 cursor-pointer"
                            onClick={() => handleDeleteUser(user._id)}
                          ></i>
                        </>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
      </div>
    </>
  );
};
export default Users;
