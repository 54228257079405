import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import CheckAuth from "./CheckAuth";
import jwtDecode from "jwt-decode";

const NavItem = ({ to, children, isadmin }) => {
  return (
    <NavLink
      to={to}
      className={({ isActive }) =>
        isActive
          ? "font-bold text-blue-600 underline underline-offset-4 uppercase"
          : "text-white uppercase"
      }
    >
      {children}
    </NavLink>
  );
};

const Navbar = () => {
  const [isUser, setIsUser] = useState(false);
  const [loggedIn, setLoggedIn] = useState(false);
  const [options, setOptions] = useState(false);
  const [role, setRole] = useState("")

  useEffect(() => {
    (async () => {
      const token = await localStorage.getItem("crm_admin_token");
      if (token) {
        const decodedToken = jwtDecode(token);
        const { role } = decodedToken;
        console.log(role);
        setRole(role)
        setLoggedIn(true);
        if (role === "User") {
          setIsUser(true);
        }
      }
    })();
  }, []);

  return (
    <div
      className={`h-12 w-full ${loggedIn ? "flex" : "hidden"
        } justify-center items-center  gap-5 space-x- text-white sticky top-0 left-0 bg-gray-900 border-b`}
    >

      {isUser && (
        <>
         <NavItem to={"/"}>HOME</NavItem>
      <NavItem to={"/searchLeads"}>SEARCH-LEADS</NavItem>
        </>
      )}
     


      {role === "Admin" && (
        <>
          <NavItem to={"/all-users"}>CRM_USERS</NavItem>
          <NavItem to={"/searchLeads"}>SEARCH-LEADS</NavItem>

          <NavItem to={"/addLeads"}>

            <CheckAuth>ADD-LEADS</CheckAuth>
          </NavItem>
          <NavItem to={"/assignedByMe"} >
            <CheckAuth>assigned by me</CheckAuth>
          </NavItem>

          <NavItem to={"/leads"}>
            <CheckAuth>LEADS</CheckAuth>
          </NavItem>
        </>
      )}


       {role === "Manager" && (
        <>
    
        <NavItem to={"/searchLeads"}>SEARCH-LEADS</NavItem>
          <NavItem to={"/addLeads"}>
            <CheckAuth>ADD-LEADS</CheckAuth>
          </NavItem>
          <NavItem to={"/assignedByMe"} >
            <CheckAuth>assigned by me</CheckAuth>
          </NavItem>
        </>
      )} 

      <button onClick={() => setOptions(!options)}>➥</button>
      <div
        className={`space-x-4 ${options ? "scale-100" : "scale-0"
          } duration-200 origin-left`}
      >
        <NavItem to={"/login"}>LOGIN</NavItem>
        <NavItem to={"/register"}>REGISTER</NavItem>
        <NavItem to={"/logout"}>LOGOUT</NavItem>
      </div>
    </div>
  );
};

export default Navbar;
