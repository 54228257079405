import React, { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
// toastify
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import jwt_decode from "jwt-decode";

const toastOptions = {
  position: "top-right",
  autoClose: 2000,
  hideProgressBar: false,
  newestOnTop: false,
  closeOnClick: true,
  pauseOnFocusLoss: true,
  draggable: true,
  pauseOnHover: true,
  theme: "dark",
};

const Leads = () => {
  const navigate = useNavigate();
  const [currentUser, setCurrentUser] = useState({
    id: "",
    firstName: "",
  });
  const initialData = {
    name: "",
    phone: "",
    countryName: "",
    investment: 0,
    tradeIn: "",
    language: "",
    reference: "",
    assigned_to: "unassigned",
    assigned_by: "",
    pool: "unassigned",
    remark: "",
  }
  const [data, setData] = useState(initialData);

  const [allUsers, setAllUsers] = useState([]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (
      (name === "phone") &&
      allLeads.some((user) => user[name] === value)
    ) {
      toast.error(`${name} already exists`, toastOptions);
      return;
    }
    setData({ ...data, [name]: value });
  };

  const handleValidation = (e) => {
    e.preventDefault();

    if (data.name === "") {
      toast.error("please enter Name", toastOptions);
      return;
    }

    if (data.phone === "") {
      toast.error("please enter your phone number", toastOptions);
      return;
    }
    if (data.countryName === "") {
      toast.error("please enter your countryName", toastOptions);
      return;
    }

    if (data.investment === 0) {
      toast.error("please enter your investment", toastOptions);
      return;
    }
    if (data.segments === "") {
      toast.error("please enter your segments", toastOptions);
      return;
    }

    if (data.language === "") {
      toast.error("please enter your language", toastOptions);
      return;
    }

    if (data.reference === "") {
      toast.error("please enter your reference", toastOptions);
      return;
    }
    if (data.remark === "") {
      toast.error("enter remark", toastOptions);
      return;
    }
    if (data.title === "") {
      toast.error("enter title", toastOptions);
      return;
    }

    if (data.pool === "") {
      toast.error("please enter your pool name", toastOptions);
      return;
    }
    handleSubmission();
  };

  useEffect(() => {
    (async () => {
      try {
        const token = localStorage.getItem("crm_admin_token");
        const userId = jwt_decode(token).id;
        if (userId !== undefined) {
          const res = await axios.get(
            // `https://crm-alpha-blond.vercel.app/api/v1/all-users/${userId}`
            `https://crm-alpha-blond.vercel.app/api/v1/all-users/${userId}`
          );
          setAllUsers(res.data);
        } else {
          toast.info("You need to be logged in first", toastOptions);
        }
      } catch (error) {
        toast.error("An error occurred while fetching users.", toastOptions);
      }
    })();
  }, []);

  useEffect(() => {
    (async () => {
      try {
        const token = localStorage.getItem("crm_admin_token");
        const user = jwt_decode(token);
        setCurrentUser({
          id: user.id,
          firstName: user.firstName,
        });
        setData({ ...data, assigned_by: user.id });
      } catch (error) {
        toast.error("An error occurred while fetching users.", toastOptions);
      }
    })();
  }, []);

  const [pools, setPools] = useState([]);

  useEffect(() => {
    (async () => {
      try {
        const res = await axios.get(
          // `https://crm-alpha-blond.vercel.app//api/v1/all-pools`
          `https://crm-alpha-blond.vercel.app/api/v1/all-pools`
        );
        console.log(res.data);
        setPools(res.data);
      } catch (error) {
        toast.error("An error occurred while fetching pools.", toastOptions);
      }
    })();
  }, []);

  const [allLeads, setAllLeads] = useState([]);

  useEffect(() => {
    (async () => {
      try {
        const token = localStorage.getItem("crm_admin_token");
        const userId = jwt_decode(token).id;
        const res = await axios.get(
          // `https://crm-alpha-blond.vercel.app/api/v1/getAll-leads/${userId}`
          `https://crm-alpha-blond.vercel.app/api/v1/getAll-leads/${userId}`
        );
        setAllLeads(res.data);
      } catch (error) {
        // toast.error("An error occurred while fetching users.", toastOptions);
      }
    })();
  }, []);

  const handleSubmission = async () => {
    console.log(data);
    try {
      const res = await axios.post(
        // "https://crm-alpha-blond.vercel.app/api/v1/addLeads",
        `https://crm-alpha-blond.vercel.app/api/v1/addLeads`,
        data,
        {
          header: {
            "content-type": "application/json",
          },
        }
      );
      toast.success("Lead added successfully!", toastOptions);
      setData(initialData)
    } catch (error) {
      toast.error("An error occurred while adding the lead.", toastOptions);
    }
  };

  return (
    <>
      <div className="w-full flex gap-4 flex-col justify-center items-center bg-gray-900 py-16 px-4 min-h-screen">
        <p className=" font-bold  bg-gray-900 items-center justify-center text-white text-2xl">
          {" "}
          ADD LEAD DETAILS
        </p>
        <div className="flex justify-center items-center w-full">
          <form
            method="POST"
            onSubmit={(e) => {
              e.preventDefault(); 
              handleValidation(e); 
            }}
            className="w-full flex justify-center items-center"
          > 
          <div className=""></div>
            <div className="h-full w-1/2 flex flex-col justify-center items-center space-y-4">
              <p className="text-white">Name</p>
              <input
                className="p-2 text-black rounded-lg border border-primary focus:outline-primary placeholder:capitalize w-4/5 sm:w-3/6 shadow-black shadow-sm"
                type="text"
                name="name"
                placeholder="Enter Name"
                value={data.name}
                onChange={handleChange}
              />


              <p className="text-white">Phone</p>
              <input
                className="p-2 rounded-lg border border-primary focus:outline-primary placeholder:capitalize w-4/5 sm:w-3/6 shadow-black shadow-sm"
                type="number"
                name="phone"
                value={data.phone}
                placeholder="Enter Phone Number"
                onChange={handleChange}
              />

              <p className="text-white">Country</p>
              <input
                className="p-2 rounded-lg border border-primary focus:outline-primary placeholder:capitalize w-4/5 sm:w-3/6 shadow-black shadow-sm"
                type="text"
                value={data.countryName}
                name="countryName"
                placeholder="Enter Country"
                onChange={handleChange}
              />
              <p className="text-white">Investment</p>
              <input
                className="p-2 rounded-lg border border-primary focus:outline-primary placeholder:capitalize w-4/5 sm:w-3/6 shadow-black shadow-sm"
                type="number"
                value={data.investment}
                name="investment"
                placeholder="Enter investment"
                onChange={handleChange}
              />

              <p className="text-white">Title</p>
              <input
                className="p-2 rounded-lg border border-primary focus:outline-primary placeholder:capitalize w-4/5 sm:w-3/6  shadow-black shadow-sm"
                type="text"
                name="title"
                value={data.title}
                placeholder="Enter title"
                onChange={handleChange}
              />

            </div>
            <div className="h-full w-1/2 flex flex-col justify-center items-center space-y-4">
              <p className="text-white">Language</p>
              <input
                className="p-2 rounded-lg border border-primary focus:outline-primary placeholder:capitalize w-4/5 sm:w-3/6 shadow-black shadow-sm"
                type="text"
                value={data.language}
                name="language"
                placeholder="Enter language"
                onChange={handleChange}
              />

              <p className="text-white">Reference</p>
              <input
                className="p-2 rounded-lg border border-primary focus:outline-primary placeholder:capitalize w-4/5 sm:w-3/6 shadow-black shadow-sm"
                type="text"
                name="reference"
                value={data.reference}
                placeholder="Enter reference"
                onChange={handleChange}
              />

              <p className="text-white">Segments</p>
              <input
                className="p-2 rounded-lg border border-primary focus:outline-primary placeholder:capitalize w-4/5 sm:w-3/6 shadow-black shadow-sm"
                type="text"
                name="segments"
                value={data.segments}
                placeholder="Enter segments"
                onChange={handleChange}
              />

              <p className="text-white">Assigned by</p>
              <input
                className="p-2 rounded-lg border border-primary focus:outline-primary placeholder:capitalize placeholder:text-white w-4/5 sm:w-3/6 shadow-black shadow-sm capitalize"
                type="text"
                name="assigned_by"
                value={data.assigned_by.firstName}
                placeholder={currentUser.firstName}
                disabled={true}
                onChange={handleChange}
              />

              <p className="text-white">Assigned to</p>
              <select
                name="assigned_to"
                onChange={handleChange}
                value={data.assigned_to.firstName}
                className="p-2 rounded-lg border border-primary focus:outline-primary placeholder:capitalize w-4/5 sm:w-3/6 shadow-black shadow-sm"
              >
                <option defaultValue="unassigned">unassigned</option>
                {allUsers.map((user, index) => {
                  return (
                    <option key={index} value={user._id}>
                      {user.firstName}
                    </option>
                  );
                })}
                
              </select>
            </div>
          </form>
        </div>
        <p className="text-white">Pool</p>
        <select
          name="pool"
          onChange={handleChange}
          value={data.pool}
          className="p-2 rounded-lg border border-primary focus:outline-primary placeholder:capitalize w-4/5 sm:w-3/6 lg:w-1/5 shadow-black shadow-sm"
        >
          <option value="unassigned">new pool</option>
          {pools.map((poolname, index) => {
            return (
              <option key={index} value={poolname}>
                {poolname}
              </option>
            );
          })}
        </select>
        <input
          className="p-2 rounded-lg border border-primary focus:outline-primary placeholder:capitalize w-4/5 sm:w-3/6 lg:w-1/5 shadow-black shadow-sm capitalize"
          type="text"
          name="pool"
          placeholder="Enter Pool"
          // disabled={data.pool !== "unassigned"}
          onChange={handleChange}
        />
        <p className="text-white">Remark</p>
        <textarea
          name="remark"
          value={data.remark}
          onChange={handleChange}
          className="p-2 rounded-lg border border-primary focus:outline-primary placeholder:capitalize w-4/5 sm:w-3/6 lg:w-1/5 shadow-black shadow-sm"
          cols="30"
          rows="10"
        ></textarea>
        <button
          onClick={handleValidation}
          className="bg-gradient-to-r from-teal-400 to-blue-500 hover:from-pink-500 hover:to-orange-500 text-white font-semibold px-4 py-2 rounded"
          type="submit"
        >
          ADD LEADS
        </button>
      </div>
    </>
  );
};

export default Leads;

