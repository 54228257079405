import React, { useState, useEffect } from "react";

const AppAccessControl = ({ children }) => {
  const [isAccessAllowed, setIsAccessAllowed] = useState(false);

  // Define the start and end times for allowed access (9 am to 7 pm)
  const startTime = 9;
  const endTime = 19;

  useEffect(() => {
    // Get the current hour
    const currentHour = new Date().getHours();

    // Check if the current hour is within the allowed time range
    if (currentHour >= startTime && currentHour < endTime) {
      setIsAccessAllowed(true);
    } else {
      setIsAccessAllowed(false);
    }
  }, []);

  return (
    <div>
      {isAccessAllowed ? (
        children // Render the app content if access is allowed
      ) : (
        <div className="flex flex-col justify-center items-center gap-4 h-screen bg-gray-900 text-white">
          <h1 className="text-red-400">Access Denied</h1>
          <p>
            Access to the app is only allowed between <b>9 am and 7 pm</b>.
          </p>
        </div>
      )}
    </div>
  );
};

export default AppAccessControl;
