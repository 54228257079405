// libraries
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { ToastContainer } from "react-toastify";

// pages
import Home from "./pages/Home";
import Navbar from "./components/Navbar";
import Login from "./pages/Login";
import Register from "./pages/Register";
import CheckAuth from "./components/CheckAuth";
import Leads from "./pages/Leads";
import AddLeads from "./pages/admin/AddLeads";
import Users from "./pages/admin/AllUsers";
import SearchLeads from "./pages/admin/SearchLead";
import Logout from "./components/Logout";
import AppAccessControl from "./components/AppAccessControl";
import AssignedByMe from "./pages/AssignedByMe";
import ForgetPassword from "./pages/ForgetPassword";
import VerifyOtp from "./pages/VerifyOtp";


function App() {
  return (
    <>
      <AppAccessControl>
        <div className="App">
          <Router>
            <Navbar />
            <Routes>
              <Route
                index
                element={
                  <CheckAuth>
                    <Home />
                  </CheckAuth>
                }
              />
              <Route
                path="/addLeads"
                element={
                  <CheckAuth>
                    <AddLeads />
                  </CheckAuth>
                }
              />
              <Route
                path="/assignedByMe"
                element={
                  <CheckAuth>
                    <AssignedByMe />
                  </CheckAuth>
                }
              />
              <Route
                path="/all-users"
                element={
                  <CheckAuth>
                    <Users />
                  </CheckAuth>
                }
              />
              <Route
                path="/leads"
                element={
                  <CheckAuth>
                    <Leads />
                  </CheckAuth>
                }
              />
              <Route
                path="/searchLeads"
                element={
                  <CheckAuth>
                    <SearchLeads />
                  </CheckAuth>
                }
              />
              <Route path="/login" element={<Login />} />
              <Route path="/register" element={<Register />} />
              <Route  path="/forget-password" element={<ForgetPassword/>} /> 
              <Route path="/verify-otp" element={<VerifyOtp/>} />
              <Route
                path="/logout"
                element={
                  <CheckAuth>
                    <Logout />
                  </CheckAuth>
                }
              />
            </Routes>
            <ToastContainer />
          </Router>
        </div>
      </AppAccessControl>
    </>
  );
}
export default App;



