import React, { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const toastOptions = {
    position: "top-center",
    autoClose: 2000,
    hideProgressBar: false,
    newestOnTop: false,
    closeOnClick: true,
    pauseOnFocusLoss: true,
    draggable: true,
    pauseOnHover: true,
    theme: "dark",
};


function Verification() {
    const [email,setEmail] = useState("")
    const [otp, setOtp] = useState('');
    const [newpassword, setNewpassword] = useState("");
    const [confirmpassword, setConfirmPassword] = useState("")
    const navigate = useNavigate();

 const handleEmailChange=(e)=>{
    setEmail(e.target.value);
 }

    const handleOtpChange = (e) => {
        setOtp(e.target.value);
    };

    const handleNewpasswordChange = (e) => {
        setNewpassword(e.target.value);
    };

    const handleconfirmpasswordChange = (e) => {
        setConfirmPassword(e.target.value);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            const response = await fetch('https://crm-alpha-blond.vercel.app/api/v1/verify-otp', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ otp }),
            });
            toast.success("OTP verification successful", toastOptions)
            console.log("OTP verification successful")
            const data = await response.json();
            console.log(data);
            navigate("/")

        } catch (error) {
            console.error('Error verifying OTP:', error);
            toast.error("OTP verification successful", toastOptions)
        }
    };

    return (
        <div className="h-full  xl:h-screen w-full bg-gray-900 py-16 px-4">
            <div className="flex flex-col space-x-12 space-y-6 justify-center items-center"></div>
            <div className="flex flex-col items-center justify-center ">
                <form onSubmit={handleSubmit} className="bg-white  shadow rounded lg:w-1/3  md:w-1/2 w-full p-10 mt-16">
                    <p
                        tabIndex={0}
                        role="heading"
                        aria-label="Reset your password"
                        className="text-2xl underline text-center mt-4 font-extrabold leading-6 text-gray-800 "
                    >
                        OTP VERIFICATION
                    </p>
                    <div>
                    <label className="text-sm font-medium leading-none text-gray-800">
                           Email
                        </label>
                        <input
                            role="input"
                            value={email}
                            onChange={handleEmailChange}
                            type="text"
                            className="bg-gray-200 border rounded focus:outline-none text-xs font-medium leading-none text-gray-800 py-3 w-full pl-3 mt-2"
                        />

                        <label className="text-sm font-medium leading-none text-gray-800">
                            New Password
                        </label>
                        <input
                           
                            role="input"
                            value={newpassword}
                            onChange={handleNewpasswordChange}
                            type="text"
                            className="bg-gray-200 border rounded focus:outline-none text-xs font-medium leading-none text-gray-800 py-3 w-full pl-3 mt-2"
                        />

                        <label className="text-sm font-medium leading-none text-gray-800">
                            Confirm Password
                        </label>
                        <input
                           
                            role="input"
                            value={confirmpassword}
                            onChange={handleconfirmpasswordChange}
                            type="text"
                            className="bg-gray-200 border rounded focus:outline-none text-xs font-medium leading-none text-gray-800 py-3 w-full pl-3 mt-2"
                        />

                        <label className="text-sm items-center justify-center font-medium leading-none text-gray-800">
                            OTP
                        </label>
                        <input
                            aria-label="enter email address"
                            role="input"
                            value={otp}
                            onChange={handleOtpChange}
                            type="otp"
                            placeholder="enter otp"
                            className="bg-gray-200  pt-3 border rounded focus:outline-none text-xs font-medium leading-none text-gray-800 py-3 w-full pl-3 mt-2"
                        />

                    </div>

                    <div className="mt-8">
                        <button
                            role="button"
                            aria-label="reset password"
                            className="focus:ring-2 focus:ring-offset-2 focus:ring-indigo-700 text-sm font-semibold leading-none text-white focus:outline-none bg-indigo-700 border rounded hover:bg-indigo-600 py-4 w-full"
                        >
                            Verify OTP
                        </button>

                    </div>
                </form>

            </div>
        </div>

    );
}

export default Verification;
