import React, { useState } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import jwtDecode from "jwt-decode";
import axios from "axios";

const toastOptions = {
  position: "top-center",
  autoClose: 2000,
  hideProgressBar: false,
  newestOnTop: false,
  closeOnClick: true,
  pauseOnFocusLoss: true,
  draggable: true,
  pauseOnHover: true,
  theme: "dark",
};

const SearchLeads = () => {
  const [phone, setPhone] = useState("");
  const [searchedLeads, setSearchedLeads] = useState(null);
  const [notFound, setNotFound] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleSearch = async () => {
    try {
      setLoading(true); // Show loading indicator
      const token = await localStorage.getItem("crm_admin_token");
      if (token) {
        const decodedToken = jwtDecode(token);
        const { id, role, firstName } = decodedToken;
        console.log(id);
        console.log(role);
        console.log(firstName);



        const res = await axios.get(
          `https://crm-alpha-blond.vercel.app/api/v1/${id}/search-leads/${phone}`,
          {
            headers: {
              "content-type": "application/json",
            },
          }
        );
        const data = res.data;

        if (data) {
          // Lead found
          setSearchedLeads(data);
          // console.log(searchedLeads.assigned_by.id);
          setNotFound(false);
          toast.success("Searched phone number successfully!", toastOptions);
        } else {
          // No lead found
          setSearchedLeads(null);
          setNotFound(true);
          // toast.error("Phone number not found.", toastOptions);
        }
      }
    } catch (error) {
      setNotFound(true);

    } finally {
      setLoading(false); // Hide loading indicator whether success or failure
    }
  };

  return (
    <div className="h-full  xl:h-screen w-full bg-gray-900 py-16 px-4">
      <div className="flex flex-col items-center justify-center">
        <div className="flex flex-col justify-center items-center gap-4 w-1/5">
          <label className="text-xl font-extrabold text-white leading-6">
            Phone Number:
          </label>
          <input
            className="bg-gray-200 border rounded focus:outline-none text-xs font-medium leading-none text-gray-800 py-3 w-full pl-3 mt-2"
            type="text"
            value={phone}
            onChange={(e) => setPhone(e.target.value)}
          />
          <Link to="/searchLeads">
            <button
              className="bg-gradient-to-r from-teal-400 to-blue-500 hover:from-pink-500 hover:to-orange-500 text-white font-semibold px-4 py-2 rounded"
              onClick={handleSearch}

            >
              {loading ? "Searching..." : "Search"}
            </button>
          </Link>
        </div>

        <div>
          {notFound ? (
            <p className="text-white font-bold">Phone number not found.</p>
          ) : searchedLeads ? (
            <div className="text-white flex gap-10">
              <p className="font-bold">Name:<br></br> {searchedLeads.name}</p>
              {/* <p className="font-bold">Email: {searchedLeads.email}</p> */}
              <p className="font-bold">Phone:
               {searchedLeads.phone}</p>
              <p className="font-bold">countryName: {searchedLeads.countryName}</p>
              <p className="font-bold">investment: {searchedLeads.investment}</p>
              <p className="font-bold">Segments: {searchedLeads.segments}</p>
              <p className="font-bold">language: {searchedLeads.language}</p>
              <p className="font-bold">Title:{searchedLeads.title}</p>
              <p className="font-bold">reference: {searchedLeads.reference}</p>
              <p className="font-bold">
                Assigned By: {searchedLeads.assigned_by.firstName}
              </p>
              <p className="font-bold">
                Assigned To: {searchedLeads.assigned_to.firstName}
              </p>
            </div>
          ) : null}
        </div>

      </div>
    </div>
  );
};



export default SearchLeads;


