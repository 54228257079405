import React, { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const toastOptions = {
  position: "top-center",
  autoClose: 2000,
  hideProgressBar: false,
  newestOnTop: false,
  closeOnClick: true,
  pauseOnFocusLoss: true,
  draggable: true,
  pauseOnHover: true,
  theme: "dark",
};


function ForgetPassword() {
  const [email, setEmail] = useState("");
  const navigate = useNavigate();

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handleForgotPassword = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post("https://crm-alpha-blond.vercel.app/api/v1/forget-password", { email: email });
      console.log(response.data);
      toast.success("OTP sent to your email", toastOptions);
      navigate("/verify-otp")

    } catch (error) {
      console.error("An Error occurred to sending otp: ", error);
      toast.error("An Error occurred to sending otp: ",toastOptions)
    }
  };

  return (
    <>
      <div className="h-full  xl:h-screen w-full bg-gray-900 py-16 px-4">
        <div className="flex flex-col space-x-12 space-y-6 justify-center items-center"></div>
        <div className="flex flex-col items-center justify-center ">
          <form
            onSubmit={handleForgotPassword}
            className="bg-white  shadow rounded lg:w-1/3  md:w-1/2 w-full p-10 mt-16"
          >
            <p
              tabIndex={0}
              role="heading"
              aria-label="Reset your password"
              className="text-2xl font-extrabold leading-6 text-gray-800 "
            >
              Reset your password
            </p>
            <div>
              <label className="text-sm font-medium leading-none text-gray-800">
                Email
              </label>
              <input
                aria-label="enter email address"
                role="input"
                value={email}
                onChange={handleEmailChange}
                type="email"
                className="bg-gray-200 border rounded focus:outline-none text-xs font-medium leading-none text-gray-800 py-3 w-full pl-3 mt-2"
              />
            </div>
            <div className="mt-8">
              <button
                role="button"
                aria-label="reset password"
                className="focus:ring-2 focus:ring-offset-2 focus:ring-indigo-700 text-sm font-semibold leading-none text-white focus:outline-none bg-indigo-700 border rounded hover:bg-indigo-600 py-4 w-full"
              >
               Send OTP
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}
export default ForgetPassword;


// import React, { useState } from 'react';

// function ForgetPassword() {
//   const [email, setEmail] = useState('');
//   const [message, setMessage] = useState('');

//   const handleEmailChange = (e) => {
//     setEmail(e.target.value);
//   };

//   const handleSubmit = async (e) => {
//     e.preventDefault();

//     try {
//       const response = await fetch('/forgot-password', {
//         method: 'POST',
//         headers: {
//           'Content-Type': 'application/json',
//         },
//         body: JSON.stringify({ email }),
//       });

//       const data = await response.json();
//       setMessage(data.message);
//     } catch (error) {
//       console.error('Error sending OTP:', error);
//       setMessage('Failed to send OTP');
//     }
//   };

//   return (
//     <div>
//       <h1>Forgot Password</h1>
//       <form onSubmit={handleSubmit}>
//         <div>
//           <label>Email:</label>
//           <input type="email" value={email} onChange={handleEmailChange} />
//         </div>
//         <button type="submit">Send OTP</button>
//       </form>
//       <p>{message}</p>
//     </div>
//   );
// }

// export default ForgetPassword;