import { createSlice } from "@reduxjs/toolkit";

const tabSlice = createSlice({
  name: "selectedTab",
  initialState: "all",
  reducers: {
    setSelectedTab: (state, actions) => (state = actions.payload),
  },
});

export const { setSelectedTab } = tabSlice.actions;

export default tabSlice.reducer;
