import { combineReducers } from "redux";
import counterReducer from "./reducers/counter";
import selectedTabReducer from "./reducers/selectedTab";

const rootReducer = combineReducers({
  counter: counterReducer,
  selectedTab : selectedTabReducer
});

export default rootReducer;
